import React from 'react';

const FactionSelector = (props) => {
  return (
    <div className="classic-classes-left">
      <div className={`classic-classes-tabs ${props.faction}`}>
        <a href="#" className={`btn btn-classes-tab tab-1 ${props.faction === 'Asmodian' ? 'active' : ''}`}
          onClick={(e) => props.handleSelectFaction(e, 'Asmodian')}>
          <span>Asmodian</span>
        </a>
        <a href="#" className={`btn btn-classes-tab tab-2 ${props.faction === 'Elyos' ? 'active' : ''}`}
          onClick={(e) => props.handleSelectFaction(e, 'Elyos')}>
          <span>Elyos</span>
        </a>
      </div>
    </div>
  );
};
export default FactionSelector;
