import React from 'react';
import ExitLagBanner from './img/exitlag.png';

const Affiliate = () => {
  return (
    <div className="max-w-7xl mx-auto bg-gray-100 rounded-xl overflow-hidden md:max-w-7xl w-4/5 cursor-pointer bg-opacity-60"
      onClick={() => window.open('https://www.exitlag.com/refer/5848364', '_blank')}>
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <img className="h-48 w-full object-cover md:h-full" src={ExitLagBanner} alt="Affiliate photo" />
        </div>
        <div className="p-8">
          <div className="uppercase tracking-wide text-sm text-red-500 font-semibold">ExitLag</div>
          <p className="block mt-1 text-xl leading-tight font-medium text-gray-800 hover:cursor-pointer">
            Kill &lt;and friends&gt; with lowerping!
          </p>
          <p className="mt-2 text-gray-800">Tired of losing to &lt;and friends&gt; Lower your ping and make them cry.</p>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
