import React from 'react';
import FactionSelector from './factionSelector';
import Affiliate from './affiliate';

const BackGround = (props) => {
  return (
    <div id="classic" className="">
      <div className="classic-inner">
        <div id="classes" className={`classic-classes ${props.faction}`}>
          <FactionSelector faction={props.faction}
            handleSelectFaction={props.handleSelectFaction} />
          {props.chosenApp ? (
            <>
              <div className="flex justify-center item-center py-10">
                <Affiliate />
              </div>
              <props.chosenApp faction={props.faction}/>
            </>
          ): (
            <div className="classic-classes-characters active" key={props.faction}>
              <div key={`${props.faction}-1`} className="classic-classes-character character-1 animate__slide-right"></div>
              <div key={`${props.faction}-2`} className="classic-classes-character character-2 animate__slide-right"></div>
              <div key={`${props.faction}-3`} className="classic-classes-character character-3 animate__slide-left"></div>
              <div key={`${props.faction}-4`} className="classic-classes-character character-4 animate__slide-left"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackGround;
