import React, {useState, useEffect} from 'react';

const quality = {
  superior: {
    qb: 400,
    qm: 40,
  },
  heroic: {
    qb: 300,
    qm: 30,
  },
  fabled: {
    qb: 200,
    qm: 20,
  },
  eternal: {
    qb: 150,
    qm: 15,
  },
  mythic: {
    qb: 125,
    qm: 12.5
  }
};

const range = {
  10: {
    rm: 1,
    rl: 850
  },
  15: {
    rm: 0.85,
    rl: 700
  },
};

const supplement = {
  none: 0,
  lesser: 50,
  normal: 100,
  greater: 150,
};


const WeaponGrade = (props) => {
  return (
    <div className="my-1 px-1 w-full overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
      <label>
        <span className="text-white">Item Grade</span>
        <select className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
          value={props.weaponGrade} onChange={props.handleWeaponGradeChange}>
          <option className="text-green-500" value="superior">Superior</option>
          <option className="text-blue-500" value="heroic">Heroic</option>
          <option className="text-yellow-400" value="fabled">Fabled</option>
          <option className="text-yellow-500" value="eternal">Eternal</option>
          <option className="text-purple-500" value="mythic">Mythic</option>
        </select>
      </label>
    </div>
  );
};

const LevelInput = (props) => {
  return (
    <div className="my-1 px-1 w-full overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
      <label>
        <span className="text-white">Item Level (max: 65)</span>
        <input type="number" className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
          value={props.level}
          max={65} min={10}
          onChange={props.handleLevelChange}/>
      </label>
    </div>
  );
};

const Supplement = (props) => {
  return (
    <div className="my-1 px-1 w-full overflow-hidden sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/3">
      <label>
        <span className="text-white">Supplement</span>
        <select className="form-select block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 text-black"
          value={props.sup} onChange={props.handleSupChange}>
          <option className="text-black" value="none">None</option>
          <option className="text-blue-500" value="lesser">Lesser</option>
          <option className="text-yellow-400" value="normal">Normal</option>
          <option className="text-yellow-500" value="greater">Greater</option>
        </select>
      </label>
    </div>
  );
};


const Enchantment = () => {
  const [weaponGrade, setWeaponGrade] = useState('fabled');
  const [level, setLevel] = useState(50);
  const [sup, setSup] = useState('none');
  const [results, setResults] = useState([]);

  const handleWeaponGradeChange = (e) => {
    setWeaponGrade(e.target.value);
  };

  const handleLevelChange = (e) => {
    setLevel(e.target.value > 65 ? 65 : e.target.value);
  };

  const handleSupChange = (e) => {
    setSup(e.target.value);
  };

  const getSuccessRate = (sb, rm, rl, qb, qm, stoneLevel, itemLevel) => {
    return (sb + Math.min(rl, rm  * (qb + qm * (stoneLevel - itemLevel)))) / 1000;
  };

  useEffect(() => {
    const {qb, qm} = quality[weaponGrade];
    const sb = supplement[sup];
    const obj = {};
    const finalResults = [];
    for (let stoneLevel = level; stoneLevel < level + 100; stoneLevel++) {
      const lowerRange = getSuccessRate(sb, range[10].rm, range[10].rl, qb, qm, stoneLevel, level);
      const higherRange = getSuccessRate(sb, range[15].rm, range[15].rl, qb, qm, stoneLevel, level);
      if (obj[stoneLevel-1]
        && obj[stoneLevel-1]['1-10'] === lowerRange
        && obj[stoneLevel-1]['11-15'] === higherRange
        && (weaponGrade === 'mythic' || weaponGrade === 'eternal' || weaponGrade === 'fabled')) {
        break;
      }
      if (obj[stoneLevel-1]
        && obj[stoneLevel-1]['1-10'] === lowerRange
        && (weaponGrade === 'superior' || weaponGrade === 'heroic')) {
        break;
      }

      obj[stoneLevel] = {
        '1-10': lowerRange,
      };
      if (weaponGrade === 'mythic' || weaponGrade === 'eternal' || weaponGrade === 'fabled') {
        obj[stoneLevel]['11-15'] = higherRange;
      } else {
        obj[stoneLevel]['11-15'] = 'N/A';
      }
      finalResults.unshift({[stoneLevel]: obj[stoneLevel]});
    }
    setResults(finalResults);
  }, [weaponGrade, level, sup]);

  return (
    <div className="max-w-7xl mx-auto rounded-xl md:max-w-7xl w-4/5">
      <h1 className="text-4xl text-white pb-3 uppercase font-bold font-sans">Enchantment Calculator</h1>
      <p className="py-2 text-white italic">*Special thanks to Spinlock - creator of Shugo Console*</p>
      <div className="flex flex-wrap overflow-hidden">
        <WeaponGrade weaponGrade={weaponGrade} handleWeaponGradeChange={handleWeaponGradeChange} />
        <LevelInput level={level} handleLevelChange={handleLevelChange} />
        <Supplement sup={sup} handleSupChange={handleSupChange} />
      </div>
      <div className="flex flex-col py-8">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Enchantment Stone
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      1-10
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      11-15
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {results.map((result, index) => {
                    return Object.keys(result).map((stoneLevel) => {
                      return (
                        <tr key={stoneLevel}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{stoneLevel}{index === 0 ? '+' : ''}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {result[stoneLevel]['1-10'].toFixed(2)}{index === 0 ? ' (max)' : ''}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {result[stoneLevel]['11-15'] != 'N/A' ? result[stoneLevel]['11-15'].toFixed(2) : 'N/A'}{index === 0 ? ' (max)' : ''}
                            </div>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enchantment;
