import React from 'react';
import Logo from './img/logo.svg';

const NavBar = (props) => {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <nav className="relative flex flex-wrap items-center justify-between bg-white py-1 shadow-lg z-50">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black cursor-pointer "
            onClick={() => props.handleNavClick(null)} >
            <img src={Logo} alt="loc logo" className="h-10" />
          </a>
          <button
            className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}  >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <div className={`lg:flex flex-grow items-center${navbarOpen ? ' flex' : ' hidden'}`}
          id="example-navbar-danger">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className={`nav-item ${props.selectedApp === 'translator' ? 'underline' : 'no-underline'}`}>
              <p className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75 cursor-pointer"
                onClick={() => props.handleNavClick('translator')}>
                <span className="ml-2">Translator</span>
              </p>
            </li>
            <li className={`nav-item ${props.selectedApp === 'enchantment' ? 'underline' : 'no-underline'}`}>
              <p className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-black hover:opacity-75 cursor-pointer"
                onClick={() => props.handleNavClick('enchantment')}>
                <span className="ml-2">Enchantment Calculator</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
