import React, {useState} from 'react';
import NavBar from './navbar';
import Translator from './translator';
import Enchantment from './enchantment';
import BackGround from './background';

const getComponent = (selectedApp) => {
  switch(selectedApp) {
  case 'translator':
    return Translator;
  case 'enchantment':
    return Enchantment;
  default:
    return null;
  }
};

const App = () => {
  const [selectedApp, setSelectedApp] = useState('');
  const [faction, setFaction] = useState('Asmodian');

  const handleSelectFaction = (e, value) => {
    e.preventDefault();
    setFaction(value);
  };

  const handleNavClick = (name) => setSelectedApp(name);
  return (
    <>
      <NavBar handleNavClick={handleNavClick} selectedApp={selectedApp}/>
      <BackGround
        chosenApp={getComponent(selectedApp)}
        faction={faction}
        handleSelectFaction={handleSelectFaction}/>
    </>
  );
};

export default App;
