import React, {useState, useEffect} from 'react';

const ASMO_LETTER_KEY_MAP = {
  a:{j	:{letter	:'j',	number:1},	e	:{letter	:'e',	number:1},	f	:{letter	:'f',	number:1},	g	:{letter	:'g',	number:1}},
  b:{k	:{letter	:'k',	number:1},	f	:{letter	:'f',	number:1},	g	:{letter	:'g',	number:1},	h	:{letter	:'h',	number:1}},
  c:{h	:{letter	:'h',	number:1},	c	:{letter	:'c',	number:1},	d	:{letter	:'d',	number:1},	e	:{letter	:'e',	number:1}},
  d:{i	:{letter	:'i',	number:1},	d	:{letter	:'d',	number:1},	e	:{letter	:'e',	number:1},	f	:{letter	:'f',	number:1}},
  e:{n	:{letter	:'n',	number:1},	i	:{letter	:'i',	number:1},	j	:{letter	:'j',	number:1},	k	:{letter	:'k',	number:1}},
  f:{o	:{letter	:'o',	number:1},	j	:{letter	:'j',	number:1},	k	:{letter	:'k',	number:1},	l	:{letter	:'l',	number:1}},
  g:{l	:{letter	:'l',	number:1},	g	:{letter	:'g',	number:1},	h	:{letter	:'h',	number:1},	i	:{letter	:'i',	number:1}},
  h:{m	:{letter	:'m',	number:1},	h	:{letter	:'h',	number:1},	i	:{letter	:'i',	number:1},	j	:{letter	:'j',	number:1}},
  i:{r	:{letter	:'r',	number:1},	m	:{letter	:'m',	number:1},	n	:{letter	:'n',	number:1},	o	:{letter	:'o',	number:1}},
  j:{s	:{letter	:'s',	number:1},	n	:{letter	:'n',	number:1},	o	:{letter	:'o',	number:1},	p	:{letter	:'p',	number:1}},
  k:{p	:{letter	:'p',	number:1},	k	:{letter	:'k',	number:1},	l	:{letter	:'l',	number:1},	m	:{letter	:'m',	number:1}},
  l:{q	:{letter	:'q',	number:1},	l	:{letter	:'l',	number:1},	m	:{letter	:'m',	number:1},	n	:{letter	:'n',	number:1}},
  m:{v	:{letter	:'v',	number:1},	q	:{letter	:'q',	number:1},	r	:{letter	:'r',	number:1},	s	:{letter	:'s',	number:1}},
  n:{w	:{letter	:'w',	number:1},	r	:{letter	:'r',	number:1},	s	:{letter	:'s',	number:1},	t	:{letter	:'t',	number:1}},
  o:{t	:{letter	:'t',	number:1},	o	:{letter	:'o',	number:1},	p	:{letter	:'p',	number:1},	q	:{letter	:'q',	number:1}},
  p:{u	:{letter	:'u',	number:1},	p	:{letter	:'p',	number:1},	q	:{letter	:'q',	number:1},	r	:{letter	:'r',	number:1}},
  q:{z	:{letter	:'z',	number:1},	u	:{letter	:'u',	number:1},	v	:{letter	:'v',	number:1},	w	:{letter	:'w',	number:1}},
  r:{G	:{letter	:'G',	number:3},	v	:{letter	:'v',	number:1},	w	:{letter	:'w',	number:1},	x	:{letter	:'x',	number:1}},
  s:{b	:{letter	:'b',	number:2},	s	:{letter	:'s',	number:1},	t	:{letter	:'t',	number:1},	u	:{letter	:'u',	number:1}},
  t:{c	:{letter	:'c',	number:2},	t	:{letter	:'t',	number:1},	u	:{letter	:'u',	number:1},	v	:{letter	:'v',	number:1}},
  u:{J	:{letter	:'J',	number:3},	y	:{letter	:'y',	number:1},	z	:{letter	:'z',	number:1},	G	:{letter	:'G',	number:3}},
  v:{a	:{letter	:'a',	number:2},	z	:{letter	:'z',	number:1},	G	:{letter	:'G',	number:3},	H	:{letter	:'H',	number:3}},
  w:{f	:{letter	:'f',	number:2},	a	:{letter	:'a',	number:2},	b	:{letter	:'b',	number:2},	c	:{letter	:'c',	number:2}},
  x:{g	:{letter	:'g',	number:2},	b	:{letter	:'b',	number:2},	c	:{letter	:'c',	number:2},	d	:{letter	:'d',	number:2}},
  y:{d	:{letter	:'d',	number:2},	I	:{letter	:'I',	number:3},	J	:{letter	:'J',	number:3},	a	:{letter	:'a',	number:2}},
  z:{e	:{letter	:'e',	number:2},	J	:{letter	:'J',	number:3},	a	:{letter	:'a',	number:2},	b	:{letter	:'b',	number:2}}
};
const ELYOS_LETTER_KEY_MAP = {
  a:{i	:{letter	:'i',	number:1},	d	:{letter	:'d',	number:1},	e	:{letter	:'e',	number:1}},
  b:{h	:{letter	:'h',	number:1},	c	:{letter	:'c',	number:1},	d	:{letter	:'d',	number:1}},
  c:{k	:{letter	:'k',	number:1},	f	:{letter	:'f',	number:1},	g	:{letter	:'g',	number:1}},
  d:{j	:{letter	:'j',	number:1},	e	:{letter	:'e',	number:1},	f	:{letter	:'f',	number:1}},
  e:{m	:{letter	:'m',	number:1},	h	:{letter	:'h',	number:1},	i	:{letter	:'i',	number:1}},
  f:{l	:{letter	:'l',	number:1},	g	:{letter	:'g',	number:1},	h	:{letter	:'h',	number:1}},
  g:{o	:{letter	:'o',	number:1},	j	:{letter	:'j',	number:1},	k	:{letter	:'k',	number:1}},
  h:{n	:{letter	:'n',	number:1},	i	:{letter	:'i',	number:1},	j	:{letter	:'j',	number:1}},
  i:{q	:{letter	:'q',	number:1},	l	:{letter	:'l',	number:1},	m	:{letter	:'m',	number:1}},
  j:{p	:{letter	:'p',	number:1},	k	:{letter	:'k',	number:1},	l	:{letter	:'l',	number:1}},
  k:{s	:{letter	:'s',	number:1},	n	:{letter	:'n',	number:1},	o	:{letter	:'o',	number:1}},
  l:{r	:{letter	:'r',	number:1},	m	:{letter	:'m',	number:1},	n	:{letter	:'n',	number:1}},
  m:{u	:{letter	:'u',	number:1},	p	:{letter	:'p',	number:1},	q	:{letter	:'q',	number:1}},
  n:{t	:{letter	:'t',	number:1},	o	:{letter	:'o',	number:1},	p	:{letter	:'p',	number:1}},
  o:{w	:{letter	:'w',	number:1},	r	:{letter	:'r',	number:1},	s	:{letter	:'s',	number:1}},
  p:{v	:{letter	:'v',	number:1},	q	:{letter	:'q',	number:1},	r	:{letter	:'r',	number:1}},
  q:{y	:{letter	:'y',	number:1},	t	:{letter	:'t',	number:1},	u	:{letter	:'u',	number:1}},
  r:{x	:{letter	:'x',	number:1},	s	:{letter	:'s',	number:1},	t	:{letter	:'t',	number:1}},
  s:{a	:{letter	:'a',	number:2},	v	:{letter	:'v',	number:1},	w	:{letter	:'w',	number:1}},
  t:{z	:{letter	:'z',	number:1},	u	:{letter	:'u',	number:1},	v	:{letter	:'v',	number:1}},
  u:{c	:{letter	:'c',	number:2},	x	:{letter	:'x',	number:1},	y	:{letter	:'y',	number:1}},
  v:{b	:{letter	:'b',	number:2},	w	:{letter	:'w',	number:1},	x	:{letter	:'x',	number:1}},
  w:{e	:{letter	:'e',	number:2},	z	:{letter	:'z',	number:1},	a	:{letter	:'a',	number:2}},
  x:{d	:{letter	:'d',	number:2},	y	:{letter	:'y',	number:1},	z	:{letter	:'z',	number:1}},
  y:{g	:{letter	:'g',	number:2},	b	:{letter	:'b',	number:2},	c	:{letter	:'c',	number:2}},
  z:{f	:{letter	:'f',	number:2},	a	:{letter	:'a',	number:2},	b	:{letter	:'b',	number:2}}
};

const translateUserInput = (input, isAsmoSelected = true) => {
  let translation = 'Scv ready, waiting to translate';
  if (input) {
    try {
      translation = translateHelper(input.toLowerCase(), isAsmoSelected);
    } catch (e) {
      console.log('There is an error: ', e);
      return 'There is an error. Refresh to try again';
    }
  }
  return translation;
};

const translateHelper = (input, isAsmoSelected) => {
  let str = '';
  let table = 0;
  const keyMap = isAsmoSelected ? ASMO_LETTER_KEY_MAP : ELYOS_LETTER_KEY_MAP;

  for (const i in input) {
    if (keyMap[input.charAt(i)]) {
      const inputCharacterKey = keyMap[input.charAt(i)];
      const currentTable = inputCharacterKey[Object.keys(inputCharacterKey)[table]];
      str += currentTable.letter;
      table = currentTable.number;
    } else {
      table = 0;
      str += input.charAt(i);
    }
  }

  return str;
};


const Translator = (props) => {
  const [og, setOg] = useState('');
  const [translated, setTranslated] = useState('');
  const [alert, setAlert] = useState('');

  useEffect(() => {
    setTranslated(translateUserInput(og, props.faction === 'Asmodian'));
  }, [og, props.faction]);

  const handleCopy = () => {
    navigator.clipboard.writeText(translated);
    setAlert('Copied!');
    setTimeout(() => setAlert(''), 5000);
  };

  return (
    <>
      <div className="max-w-7xl mx-auto rounded-xl md:max-w-7xl w-4/5">
        <h1 className="text-4xl text-white pb-3 uppercase font-bold font-sans">Translator</h1>
      </div>
      <div className="max-w-7xl mx-auto rounded-xl md:max-w-7xl w-4/5">
        <div className="flex flex-wrap -mx-1 overflow-hidden">
          <div className="my-1 px-1 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
            <textarea rows={8} placeholder="Your text here"
              onChange={(e) => {
                if (e.target.value && e.target.value.length > 195) return;
                setOg(e.target.value);
              }}
              spellCheck={false}
              value={og}
              className="relative w-full p-5 resize-none bg-gray-800 bg-opacity-60 rounded-lg text-lg text-yellow-400 focus:outline-none" >
              {og}
            </textarea>
          </div>
          <div className="my-1 px-1 w-full overflow-hidden sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 has-tooltip">
            <span className='tooltip bg-yellow-400 p-3 -mt-1 lg:-mt-8 rounded-lg'>{alert || 'Click to copy to clipboard'}</span>
            <textarea rows={8}
              readOnly
              onClick={handleCopy}
              value={translated}
              className="relative w-full p-5 resize-none bg-gray-800 bg-opacity-60 rounded-lg text-lg text-purple-400  focus:outline-none" >
              {translated}
            </textarea>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto rounded-xl md:max-w-7xl w-4/5">
        <p>In-Game Chat Limit: ({og.length}/195)</p>
      </div>
    </>
  );
};

export default Translator;
